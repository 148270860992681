import React, { useEffect, useState } from "react";
import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, isPlatform, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { briefcaseOutline, calendarOutline, cogOutline, todayOutline } from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/overrides.css";
import { RootState } from "typesafe-actions";
import { useDispatch, useSelector } from "react-redux";
import { BookerRoutes, GBManagerRoutes, MounterRoutes, LoginRoutes, AllRoutes } from "./routes/routes";
import { useTranslation } from "react-i18next";
import { storageGet } from "./helpers/helpers";
import { toggleWorkingOnline } from "./features/Login/redux/actions";
import { Network } from "@capacitor/network";
//import { PushNotifications } from "@capacitor/push-notifications";
import NotificationFeature from "./features/Notification/NotificationFeature";
import { clearNotification, showNotification } from "./features/Notification/redux/actions";
import { getDeviceCalendars } from "./helpers/deviceCalendar";
import { ICalendar } from "./models";
import LogRocket from "logrocket";
import { Keychain } from "@awesome-cordova-plugins/keychain";
import { Capacitor } from "@capacitor/core";
import Tracker from "@openreplay/tracker";
import OpenReplayTracker from "./helpers/OpenReplayTracker";

// LogRocket.init("svb14s/gardinbus");

const App: React.FC = () => {
  setupIonicReact({
    mode: "md"
  });

  // Init logRocket
  const authEmail = useSelector((state: RootState) => state.authReducer.email);
  const [email, setEmail] = useState<string>("");
  async function fetchUser() {
    return await Keychain.getJson("user-login").then(val => {
      setEmail(val.email);
    }).catch((err: Error) => console.log("error", err.message));
  }
  useEffect(() => {
    if (Capacitor.getPlatform() === "ios") {
      fetchUser();
    }
  }, []);
  const userMail = Capacitor.getPlatform() === "ios" ? email : authEmail;
  LogRocket.identify(String(userMail), {
    email: userMail,
    name: userMail
  });

  // Selectors
  const isAuth = useSelector((state: RootState) => state.authReducer.isAuth);
  const role = useSelector((state: RootState) => state.authReducer.role);
  const isOpenNotification = useSelector((state: RootState) => state.notificationReducer.isOpen);

  // State
  const [, setShowFirstTime] = useState<{
    calendarList: ICalendar[];
    isFirstTime: boolean;
  }>();
  // Translation
  const {
    t
  } = useTranslation();
  // Dispatch
  const dispatch = useDispatch();
  // Init
  useEffect(() => {
    // Check if it's mobile device
    if (isPlatform("cordova")) {
      // PushNotifications
      // PushNotifications.requestPermissions().then((result) => {
      //   if (result.receive) {
      //     PushNotifications.register();
      //   } else {
      //     //error
      //     dispatch(
      //       showNotification({
      //         type: "Toast",
      //         color: "warning",
      //         message: t("Error registering push notifications!"),
      //       })
      //     );
      //   }
      // });

      // On success, we should be able to receive notifications
      // PushNotifications.addListener(
      //   "registration",
      //   (token: PushNotificationToken) => {
      //
      //   }
      // );

      // Some issue with our setup and push will not work
      // PushNotifications.addListener("registrationError", (error: any) => {
      //
      // });

      // Show us the notification payload if the app is open on our device
      // PushNotifications.addListener(
      //   "pushNotificationReceived",
      //   (notification: PushNotification) => {
      //
      //   }
      // );

      // Method called when tapping on a notification
      // PushNotifications.addListener(
      //   "pushNotificationActionPerformed",
      //   (notification: PushNotificationActionPerformed) => {
      //
      //   }
      // );

      // Listener on network state
      Network.addListener("networkStatusChange", status => {
        if (!status.connected) {
          dispatch(showNotification({
            message: t("Oh appears you're working offline!"),
            type: "Top Bar",
            color: "danger"
          }));
          dispatch(toggleWorkingOnline(false));
        }
        if (status.connected) {
          dispatch(showNotification({
            message: t("Reconnected!"),
            type: "Top Bar",
            color: "success"
          }));
          dispatch(toggleWorkingOnline(true));
        } else {
          dispatch(clearNotification());
        }
      });

      //Check if it's first login
      storageGet("first_login").then(val => {
        if (!val) {
          getDeviceCalendars().then(calendars => {
            setShowFirstTime({
              calendarList: calendars,
              isFirstTime: true
            });
          });
        }
      });
    } // end of cordova functions
  }, [dispatch, t]);

  // Methods
  const renderRoutes = () => {
    switch (role) {
      case "Booker":
        return <BookerRoutes />;
      case "GBManager":
        return <GBManagerRoutes />;
      case "Mounter":
        return <MounterRoutes />;
      case "Admin":
        return <AllRoutes />;
      default:
        return <LoginRoutes />;
    }
  };
  const tracker = {
    projectKey: "7upFmX23ElV8in9SgJER",
    network: {
      capturePayload: true,
      failuresOnly: false,
      captureInIframes: false,
      ignoreHeaders: false,
      sessionTokenHeader: false
    },
    userId: userMail
  };
  if (process.env.NODE_ENV !== "development" && isPlatform("desktop")) {
    OpenReplayTracker({
      ...tracker
    });
  }
  return <IonApp>
      {isOpenNotification && <NotificationFeature />}
      <IonReactRouter>
        {isAuth ? <>
            <IonTabs>
              <IonRouterOutlet>{renderRoutes()}</IonRouterOutlet>

              <IonTabBar slot="bottom">
                {(role === "Admin" || role === "GBManager" || role === "Mounter") && <IonTabButton tab="dashboard" href="/dashboard">
                    <IonIcon icon={todayOutline} />
                    <IonLabel>{t("DashboardPage")}</IonLabel>
                  </IonTabButton>}
                {/* <IonTabButton tab="test" href="/test">
                    <IonIcon icon={calendarOutline} />
                    <IonLabel>{t("Test")}</IonLabel>
                  </IonTabButton> */}
                {(role === "GBManager" || role === "Booker" || role === "Admin") && <IonTabButton tab="leads" href="/leads">
                    <IonIcon icon={briefcaseOutline} />
                    <IonLabel>{t("LeadsPage")}</IonLabel>
                  </IonTabButton>}

                {(role === "Admin" || role === "Booker" || role === "GBManager" || role === "Mounter") && <IonTabButton tab="calendars" href="/calendars">
                    <IonIcon icon={calendarOutline} />
                    <IonLabel>{t("CalendarsPage")}</IonLabel>
                  </IonTabButton>}

                {(role === "Mounter" || role === "Admin") && <IonTabButton tab="mountings" href="/mountings">
                    <IonIcon icon={calendarOutline} />
                    <IonLabel>{t("MountingsPage")}</IonLabel>
                  </IonTabButton>}

                {role && <IonTabButton tab="settings" href="/settings">
                    <IonIcon icon={cogOutline} />
                    <IonLabel>{t("SettingsPage")}</IonLabel>
                  </IonTabButton>}
              </IonTabBar>
            </IonTabs>
          </> : <LoginRoutes />}
      </IonReactRouter>
    </IonApp>;
};
export default App;